import axios from 'axios';
import type { LocaleMessageObject } from 'vue-i18n';
import { createI18n } from 'vue-i18n';
import { TranslationHelper } from '@getaccept/lib-shared/src/languages/helpers/translation-setup.helper';
import { Language, Site } from '@getaccept/lib-shared/src/languages/enums/translation';
import bugsnagClient from '@getaccept/lib-shared/src/bugsnag';

const fileUrl = import.meta.env.LOKALISE_URL || 'https://translations.getaccept.com';
const project = Site.Login;
const loadedLanguages: string[] = [];
const fallbackLocale = Language.En;

export const i18n = createI18n({
  locale: fallbackLocale,
  fallbackLocale,
  legacy: false,
  allowComposition: true,
});

export function setI18nLanguage(lang) {
  i18n.global.locale.value = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html').setAttribute('lang', lang);
}

const getLanguageFromFile = async (lang: string): Promise<LocaleMessageObject> => {
  const languageObj = TranslationHelper.getLangObject(lang, project);
  const msgs = await import(`./generated/${languageObj.file}.json`);
  return msgs.default;
};

const getLanguageMessages = async (lang: string): Promise<LocaleMessageObject> => {
  const languageObj = TranslationHelper.getLangObject(lang, project);

  if (window.location.href.includes('localhost')) {
    return await getLanguageFromFile(lang);
  }

  try {
    const { data } = await axios.get(`${fileUrl}/${project}/${languageObj.key}`);
    return data;
  } catch (e) {
    bugsnagClient?.notify(e);
    return await getLanguageFromFile(lang);
  }
};

const loadFallbackLanguage = async () => {
  if (loadedLanguages.includes(fallbackLocale)) {
    return;
  }
  try {
    const messages = await getLanguageMessages(fallbackLocale);
    i18n.global.setLocaleMessage(fallbackLocale, messages);
    loadedLanguages.push(fallbackLocale);
    setI18nLanguage(fallbackLocale);
  } catch (e) {
    if (bugsnagClient) {
      bugsnagClient.notify(e);
    }
    console.error(e);
  }
};

export async function loadLanguageAsync(langKey: string) {
  const { id: lang } = TranslationHelper.getLangObject(langKey, project);
  if (loadedLanguages.includes(lang)) {
    setI18nLanguage(lang);
    return;
  }

  try {
    const messages = await getLanguageMessages(lang);
    i18n.global.setLocaleMessage(lang, messages);
    loadedLanguages.push(lang);
    setI18nLanguage(lang);
  } catch (e) {
    if (bugsnagClient) {
      bugsnagClient.notify(e);
    }
    if (lang !== fallbackLocale) {
      loadFallbackLanguage();
    }
    console.error(e);
  }
}
