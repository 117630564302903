export enum Site {
  RecipientPage = 'recipient-page',
  Login = 'login',
  Signup = 'signup',
  AppSite = 'app-site',
  MyDocuments = 'my-documents',
}

export enum Language {
  En = 'en',
  Sv = 'sv',
  Fr = 'fr',
  No = 'no',
  Nb = 'nb',
  Nn = 'nn',
  Da = 'da',
  Fi = 'fi',
  De = 'de',
  Th = 'th',
  Pl = 'pl',
  Es = 'es',
  It = 'it',
  Pt = 'pt',
  Ru = 'ru',
  Nl = 'nl',
  ZhCn = 'zh',
}
