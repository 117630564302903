export class PathChecker {
  static checkLoginVerifyEmailPaths(path: string): boolean {
    const verifyEmailPaths = [
      '/verify-email',
      '/verify-email/',
      '/login/verify-email',
      '/login/verify-email/',
    ];
    return verifyEmailPaths.includes(path);
  }
}
