import { RecaptchaAction } from '@getaccept/lib-shared/src/recaptcha/recaptcha-action';
import { ref } from 'vue';
import type { AxiosError } from 'axios';
import { useRouter } from 'vue-router';
import bugsnagClient from '@getaccept/lib-shared/src/bugsnag';
import { LoginService } from '../../api/login/login.service';
import type { LoginResponse } from '../../api/login/types/login-response';
import { RecaptchaService } from '../../api/recaptcha/recaptcha.service';
import { getErrorMessage } from '../../helpers/error-message';
import { ErrorKey } from '../../types/enums/error-key';
import type { ErrorMessage } from '../../types/error-message';
import type { ConfirmIdentityResponse } from '../types/enums/confirm-identity-response';
import { LoginErrorCode } from '../types/enums/login-error-code';
import { LoginHTTPCode } from '../types/enums/login-http-code';
import type { LoginProvider } from '../types/enums/login-provider';
import { SelectEntityService } from '../../api/select-entity/services/select-entity.service';

export const useLogin = () => {
  const router = useRouter();
  const loading = ref(false);
  const error = ref<ErrorMessage>(null);
  const connectIdentity = ref('');
  const goUrl = ref('');
  const requireOTPVerification = ref(false);
  const newLoginOTPCode = ref(false);

  const login = async ({ email, password }: { email: string; password: string }) => {
    loading.value = true;
    requireOTPVerification.value = false;
    try {
      const recaptchaToken = await RecaptchaService.getToken(RecaptchaAction.Login);
      const data: LoginResponse = await LoginService.login({
        email,
        password,
        recaptchaToken,
        connectIdentity: connectIdentity.value,
      });
      loginSuccess(data);
    } catch (error) {
      loginFailed({ requestError: error, email });
    } finally {
      loading.value = false;
    }
  };

  const authEntity = async (id: string, auth: any, go: string) => {
    if (auth === 'saml') {
      const samlUrl = await SelectEntityService.getSamlUrl(id);
      window.open(`${samlUrl}${go}`, '_self');
      return;
    }

    window.open(`/auth/${auth}/${id}${go}`, '_self');
  };

  const loginSuccess = ({ entityCount, authMethod, authEntityId }: LoginResponse) => {
    const go: string = goUrl.value ? `?go=${goUrl.value}` : '';
    const encodedGo: string = goUrl.value ? `?go=${encodeURIComponent(goUrl.value)}` : '';
    switch (entityCount) {
      case 1:
        window.open(goUrl.value || '/', '_self');
        break;
      case -2:
        error.value = getErrorMessage(ErrorKey.AccountDeleted);
        break;
      case -3: // Two factor authentication or single sign on.
        authEntity(authEntityId, authMethod, go);

        break;
      default:
        if (import.meta.env.MODE !== 'development') {
          window.open(`/profile${encodedGo}`, '_self');
          return;
        }

        if (goUrl.value) {
          router.push({ name: 'select-entity', query: { go: goUrl.value } });
          return;
        }
        router.push({ name: 'select-entity' });
    }
  };
  const loginFailed = ({
    requestError,
    email,
  }: {
    requestError?: AxiosError<{ errorCode: LoginErrorCode }>;
    email: string;
  }) => {
    let newError: ErrorMessage;
    const httpCode: number = requestError?.response?.status;
    const errorCode: number = requestError?.response?.data?.errorCode;

    if (errorCode) {
      switch (errorCode) {
        case LoginErrorCode.InvalidEmail:
          newError = getErrorMessage(ErrorKey.InvalidCredentials);
          break;
        case LoginErrorCode.PasswordExpired:
          router.push({ name: 'password-expired', query: { email } });
          break;
        case LoginErrorCode.ValidateEmail:
          newError = getErrorMessage(ErrorKey.VerifyEmail);
          break;
        case LoginErrorCode.InvalidCredentials:
          newError = getErrorMessage(ErrorKey.InvalidCredentials);
          break;
        case LoginErrorCode.VerifyOTE:
          requireOTPVerification.value = true;
          break;
        default:
          newError = getErrorMessage(ErrorKey.Unknown);
          if (bugsnagClient) {
            bugsnagClient.notify(new Error(JSON.stringify(requestError)), event => {
              event.groupingHash = JSON.stringify(requestError);
            });
          }
      }
    } else {
      switch (httpCode) {
        case LoginHTTPCode.LongerBetweenAttempts:
          newError = getErrorMessage(ErrorKey.Throttled);
          break;
        case LoginHTTPCode.AccountLocked:
          newError = getErrorMessage(ErrorKey.Locked);
          break;
        default:
          newError = getErrorMessage(ErrorKey.Unknown);
          if (bugsnagClient) {
            bugsnagClient.notify(new Error(JSON.stringify(requestError)), event => {
              event.groupingHash = JSON.stringify(requestError);
            });
          }
      }
    }
    error.value = newError;
  };

  const verifyLoginOTP = async (otp: string) => {
    error.value = null;
    loading.value = true;
    try {
      const recaptchaToken = await RecaptchaService.getToken(RecaptchaAction.VerifyLoginOTP);
      const data = await LoginService.verifyLoginOTP({ otp, recaptchaToken });
      loginSuccess(data);
    } catch (err) {
      error.value = getErrorMessage(ErrorKey.InvalidOTP);
    } finally {
      loading.value = false;
    }
  };

  const cancelOTP = () => {
    error.value = null;
    requireOTPVerification.value = false;
    newLoginOTPCode.value = false;
  };

  const generateNewLoginOTP = async () => {
    newLoginOTPCode.value = false;
    error.value = null;
    loading.value = true;
    try {
      const recaptchaToken = await RecaptchaService.getToken(RecaptchaAction.GenerateNewLoginOTP);
      await LoginService.generateNewLoginOTP(recaptchaToken);
      newLoginOTPCode.value = true;
    } catch (err) {
      error.value = getErrorMessage(ErrorKey.OTPResent);
    } finally {
      loading.value = false;
    }
  };

  const launchIdentifier = (provider: LoginProvider) => {
    const size: { height: number; width: number } = {
      height: 580,
      width: 590,
    };
    const position: { top: number; left: number } = {
      top: window.innerHeight / 2 - size.height / 2,
      left: window.innerWidth / 2 - size.width / 2,
    };
    const redirectUri = `${document.location.protocol}//${document.location.host}/auth/${provider}/`;
    const requestUrl = `/auth/${provider}/?redirect=${encodeURIComponent(redirectUri)}`;
    window.open(
      requestUrl,
      'authWindow',
      `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${size.width},height=${size.height},top=${position.top},left=${position.left}`
    );
  };

  const confirmIdentity = (data: ConfirmIdentityResponse) => {
    const go: string = goUrl.value ? `?go=${encodeURIComponent(goUrl.value)}` : '';
    switch (data.status) {
      case 1:
        window.open(`/profile${go}`, '_self');
        break;
      case 2:
        error.value = getErrorMessage(ErrorKey.UserNotConnected);
        connectIdentity.value = data.connect_identity;
        break;
      default:
        if (bugsnagClient) {
          bugsnagClient.notify(data.error);
        }
        error.value = getErrorMessage(ErrorKey.Unknown);
    }
  };

  (window as any).confirmIdentity = confirmIdentity;

  return {
    loading,
    connectIdentity,
    error,
    login,
    loginSuccess,
    launchIdentifier,
    goUrl,
    confirmIdentity,
    verifyLoginOTP,
    requireOTPVerification,
    cancelOTP,
    generateNewLoginOTP,
    newLoginOTPCode,
  };
};
