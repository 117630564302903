<template>
  <div :class="['container', { 'form-only': hideImage }]">
    <ImageCarosel v-if="!hideImage" class="hidden-xs-and-down" />
    <div class="form">
      <div class="form-inner-container">
        <FriedGetAcceptLogoSmall class="logo" />
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ImageCarosel from './ImageCarosel.vue';

export default defineComponent({
  components: {
    ImageCarosel,
  },
  props: {
    hideImage: { type: Boolean },
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: clamp(200px, 40%, 100%) 1fr;
  gap: var(--spacing-200);
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: $lg) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: $xs) {
    display: block;
  }

  .logo {
    width: 40px;
    height: 40px;
    margin-bottom: var(--spacing-200);
  }

  .form {
    width: 100%;
    height: 80%;
    position: relative;
    @media only screen and (max-width: $md), only screen and (max-height: $sm) {
      height: 100%;
    }

    .form-inner-container {
      max-width: 375px;
      width: 100%;
      position: absolute;
      right: 50%;
      top: var(--spacing-200);
      transform: translate(50%, 0);
      padding: var(--spacing-200) var(--spacing-200) var(--spacing-200) 0;

      @media only screen and (max-width: $xs) {
        max-width: 100%;
        padding: var(--spacing-100);
      }
    }
  }

  &.form-only {
    display: block;
    max-height: 600px;

    .form-inner-container {
      max-width: 100%;
      padding: 0;
    }
  }
}
</style>
