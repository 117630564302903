import 'reflect-metadata';
import './css/global.scss';
import '@getaccept/lib-shared/src/bugsnag';
import { createApp } from 'vue';
import Bugsnag from '@bugsnag/js';
import App from './App.vue';
import router from './router/router';
import 'focus-visible';
import { i18n } from './i18n/lang';

const app = createApp(App);

app.use(router);
app.use(i18n);
const bugsnagVue = Bugsnag.getPlugin('vue');
app.use(bugsnagVue);
app.mount('#login');

if (import.meta.env.MODE === 'development') {
  console.log(`%c🚀 Running "login-site" locally 🚀`, 'font-size: 1rem; line-height: 2rem;');
}
