<template>
  <div>
    <FriedParagraph class="branding">{{ $t('login') }}</FriedParagraph>
    <FriedH2 class="branding">{{ $t('single-sign-on-title') }}</FriedH2>
    <FriedParagraph class="description">{{ $t('login-sso-description') }}</FriedParagraph>
    <FriedMessage v-if="error" :title="error" :type="MessageType.Error">
      {{ $t('please-try-again-or-contact') }}
      <FriedLink href="https://help.getaccept.com/" target="_blank">{{ $t('Support') }}</FriedLink>
    </FriedMessage>
    <FriedInput
      v-model="domainName"
      data-external="login-domain-name-input"
      class="login-input"
      label="Domain name"
    />
    <FriedButton
      :disabled="!domainName"
      class="submit-button login-button"
      data-external="login-submit-button"
      :loading="loading"
      :button-type="ButtonType.Branding"
      type="submit"
      @click="loginSso(domainName)"
    >
      {{ $t('login') }}
    </FriedButton>
    <FriedButton
      class="login-button"
      data-external="login-submit-button"
      :button-type="ButtonType.Secondary"
      type="button"
      @click="$emit('cancel')"
    >
      <template #leftIcon>
        <FriedIcon icon="arrow-left" />
      </template>
      {{ $t('back-to-login') }}
    </FriedButton>
  </div>
</template>

<script lang="ts">
import { ButtonType, MessageType } from '@getaccept/fried-tofu';
import type { AxiosResponse } from 'axios';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { LoginService } from '../../api/login/login.service';

export default defineComponent({
  emits: ['cancel'],
  setup() {
    const loading = ref(false);
    const error = ref('');
    const domainName = ref('');
    const { t } = useI18n();

    const loginSso = async (domainName: string) => {
      loading.value = true;
      error.value = '';
      try {
        const redirectUrl = await LoginService.getSAMLUrl(domainName);

        if (redirectUrl) {
          window.open(redirectUrl, '_self');
          return;
        }
        loginSsoFailed();
      } catch ({ response }) {
        loginSsoFailed(response);
      }
    };

    const loginSsoFailed = (response?: AxiosResponse) => {
      loading.value = false;
      switch (response?.data?.error) {
        case 'Domain not found':
          error.value = t('domain-not-found').toString();
          break;
        case 'SAML configuration not found':
          error.value = t('saml-configuration-not-found').toString();
          break;
        default:
          error.value = t('something-went-wrong').toString();
      }
    };

    return {
      loading,
      error,
      ButtonType,
      loginSso,
      loginSsoFailed,
      domainName,
      MessageType,
    };
  },
});
</script>

<style lang="scss" scoped>
.login-input {
  width: 100%;
  margin-top: var(--spacing-100);
  margin-bottom: var(--spacing-100);
}

.description {
  color: var(--text-gray);
  padding: var(--spacing-50) 0;
}

.login-button {
  width: 100%;
}

.submit-button {
  margin-bottom: var(--spacing-100);
}
</style>
