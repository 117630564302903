import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { DateTime } from 'luxon';

export function getGlobalHandle() {
  return window as any;
}

export const testMobileNumber = (number: string): boolean =>
  parsePhoneNumberFromString(number)?.isValid() || customPhoneNumberValidaiton(number) || false;

//+61483 is a AU number that is not supported by libphonenumber-js
const AU_48: { key: string; pattern: string } = { key: 'AU', pattern: '+61483' };
const AU_49: { key: string; pattern: string } = { key: 'AU', pattern: '+61493' };
const customNumbers = [AU_48, AU_49];

export const customPhoneNumberValidaiton = (number: string): boolean =>
  customNumbers.some(customNumber => number.includes(customNumber.pattern));

export const customPhoneNumberCountryCode = (number: string): string => {
  const customNumber: { key: string; pattern: string } = customNumbers.find(customNumber =>
    number.includes(customNumber.pattern)
  );
  return customNumber?.key || '';
};

export const initialHelper = (fullName: string): string => {
  const names: string[] = fullName?.split(' ') || [];
  if (names.length === 0) {
    return '';
  }
  let initials = names[0].substring(0, 1);
  if (names.length > 1) {
    initials = initials + names[names.length - 1].substring(0, 1);
  } else {
    if (names[0].length > 1) {
      initials = names[0].substring(0, 2);
    }
  }
  return initials.toUpperCase();
};

export const validateEmail = (email: string): boolean => {
  const regex = /^(?!.*\.\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
  return !!email.match(regex);
};

export function sanitizeEmail(email: string): string {
  return email.toLowerCase().trim();
}

export function capitalizeFirstLetter(string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function throttle(func: (arg?) => any, rate: number) {
  let enabled = true;

  return function (arg?: any) {
    if (enabled) {
      func(arg);
      enabled = false;
      setTimeout(() => {
        enabled = true;
      }, rate);
    }
  };
}

export const kebabToCamelCase = (str): string =>
  str.toLowerCase().replace(/-./g, x => x[1].toUpperCase());

export const pascalToSnakeCase = (str): string =>
  str.replace(/(?:^|\.?)([A-Z])/g, (_, letter) => `_${letter.toLowerCase()}`).replace(/^_/, '');

export const convertDateStringToISOString = (dateString: string): string => {
  const date = DateTime.fromSQL(dateString);
  return date.toISO();
};

export const splitArrayIntoTwoColumns = <T>(
  inputArray: T[],
  minimumColumnLength?: number
): T[][] => {
  const newArr = [...inputArray];

  if (minimumColumnLength && newArr.length <= minimumColumnLength) {
    return [newArr, []];
  }

  const middleIndex =
    newArr.length <= minimumColumnLength * 2 ? minimumColumnLength : Math.ceil(newArr.length / 2);

  const firstColumn = newArr.slice(0, middleIndex);
  const secondColumn = newArr.slice(middleIndex, newArr.length);

  return [firstColumn, secondColumn];
};

export const extractNumberFromString = (str: string): number => {
  const num = str.replace(/[^0-9]/g, '');
  return parseInt(num) || 0;
};

export const capitalize = (text: string) => (text ? text[0].toUpperCase() + text.slice(1) : '');

export const truncateText = (text: string, maxLength = 60) => {
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength)}...`;
  }
  return text;
};

export const hexToRgb = (hex: string): number[] => {
  const bigint = parseInt(hex.replace('#', ''), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r, g, b];
};

export const rgbToHex = (rgb: number[]) => {
  const hex = rgb
    .map(x => {
      const hex = x.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    })
    .join('');
  return `#${hex}`;
};
