import { ErrorKey } from '../types/enums/error-key';
import type { ErrorMessage } from '../types/error-message';

const contactSupport = 'please-try-again-or-contact-support';

export const getErrorMessage = (key: ErrorKey): ErrorMessage => {
  const defaultTitle = 'something-went-wrong';
  switch (key) {
    case ErrorKey.AccountDeleted:
      return {
        title: 'this-account-doesnt-exist',
        description: 'this-account-has-been-deleted',
      };
    case ErrorKey.InvalidCredentials:
      return {
        title: 'try-again',
        description: 'invalid-email-or-password',
      };
    case ErrorKey.InvalidKey:
      return {
        title: defaultTitle,
        description: 'please-verify-that-your-link-is-valid',
      };
    case ErrorKey.InvalidPassword:
      return {
        title: 'old-password-doesnt-match',
        description: 'seems-like-your-old-password-was-incorrect-please-try-again',
      };
    case ErrorKey.Locked:
      return {
        title: 'too-many-login-attempts',
        description: 'weve-locked-the-account-try-again-in-1-hour-or-contact-support',
      };
    case ErrorKey.SamePassword:
      return {
        title: 'try-another-password',
        description: 'password-cannot-be-the-same-as-before-try-a-new-one',
      };
    case ErrorKey.Throttled:
      return {
        title: defaultTitle,
        description: 'please-try-again-in-10-seconds',
      };
    case ErrorKey.VerifyEmail:
      return {
        title: 'check-inbox',
        description: 'verify-your-email-address-in-the-email-we-sent-you',
      };
    case ErrorKey.WeakPassword:
      return {
        title: 'weak-password',
        description: 'weak-password-description',
      };
    case ErrorKey.UserNotConnected:
      return {
        title: 'email-could-not-be-found-as-a-user',
        description: 'user-not-connected-description',
      };
    case ErrorKey.LoadEntity:
      return {
        title: 'could-not-load-enity',
        description: contactSupport,
      };
    case ErrorKey.InvalidOTP:
      return {
        title: 'invalid_otp_code',
        description: 'please_enter_a_valid_otp_code',
      };
    case ErrorKey.OTPResent:
      return {
        title: 'otp_code_resent',
        description: 'otp_code_resent_description',
      };
    case ErrorKey.FetchIdFailed:
      return {
        title: 'could-not-fetch-user-id',
        description: contactSupport,
      };
    case ErrorKey.FetchSamlUrlFailed:
      return {
        title: 'could-not-fetch-saml-url',
        description: contactSupport,
      };
    case ErrorKey.Unknown:
    default:
      return {
        title: defaultTitle,
        description: contactSupport,
      };
  }
};
