import axios from 'axios';
import type { SelectEntity } from '@getaccept/lib-shared/src/select-entity/types/select-entity';

export class SelectEntityService {
  static async entities(): Promise<SelectEntity[]> {
    const { data } = await axios.get('/get/login/entities');
    if (!data.entities) {
      throw new Error(data);
    }
    return data.entities.map(entity => ({
      auth: entity.auth,
      id: entity.id,
      name: entity.name,
      plan: entity.plan,
      planTitle: entity.plan_title,
      role: entity.role,
    }));
  }

  static async selectEntity(entityId: string): Promise<boolean> {
    const cachebuster: number = Math.round(new Date().getTime() / 1000);
    const body = new FormData();
    body.append('entity-id', entityId);
    const { data } = await axios.post(`/get/login/entity?${cachebuster}`, body);
    if (data.status !== 1) {
      throw new Error(data);
    }
    return true;
  }

  static async getSamlUrl(entityId: string): Promise<string> {
    const url = `https://${
      import.meta.env.VUE_APP_INTERNAL_API_BASE_URL
    }/auth/saml-url/${entityId}`;
    const { data } = await axios.get(url);
    const redirectUrl = data?.redirectUrl;

    if (!redirectUrl) {
      throw new Error();
    }

    return redirectUrl;
  }
}
