import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#4285F4",
      "fill-rule": "evenodd",
      d: "M19.6 10.227c0-.71-.065-1.39-.183-2.045H10v3.868h5.382a4.6 4.6 0 0 1-1.996 3.018v2.509h3.232c1.891-1.741 2.982-4.305 2.982-7.35",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#34A853",
      "fill-rule": "evenodd",
      d: "M9.998 20c2.7 0 4.964-.895 6.619-2.423l-3.232-2.509c-.896.6-2.041.955-3.387.955-2.604 0-4.809-1.76-5.595-4.123H1.062v2.591A10 10 0 0 0 9.998 20",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#FBBC05",
      "fill-rule": "evenodd",
      d: "M4.405 11.901a6 6 0 0 1-.314-1.9c0-.659.114-1.3.314-1.9v-2.59H1.064A10 10 0 0 0 0 10c0 1.614.386 3.141 1.064 4.491z",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#EA4335",
      "fill-rule": "evenodd",
      d: "M9.998 3.977c1.469 0 2.787.505 3.823 1.495l2.868-2.868C14.957.991 12.694 0 10 0c-3.91 0-7.292 2.24-8.937 5.509l3.34 2.59c.787-2.363 2.992-4.122 5.596-4.122",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }