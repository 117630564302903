export enum SubscriptionPlan {
  Free = 'free',
  Essential = 'essential',
  Professional = 'professional',
  EnterprisePlus = 'enterpriseplus',
  // Legacy plans below
  Trial = 'trial',
  Solo = 'solo',
  Starter = 'starter',
  Business = 'business',
  Pro = 'pro',
  Enterprise = 'enterprise',
  Partner = 'partner',

  // New
  Esign = 'esign',
  WebTrial = 'webtrial',
  SalesTrial = 'salestrial',
}
