<template>
  <div v-memo="[currentBuzzword]" :class="['image-carosel', image]">
    <div class="text-container">
      <h2 class="branding title">Make room for</h2>
      <div class="word-slider">
        <h2 ref="buzzwordContainer" class="branding buzzword">{{ currentBuzzword }}</h2>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Ref } from 'vue';
import { defineComponent, computed, ref, onMounted, onBeforeUnmount } from 'vue';

export default defineComponent({
  setup() {
    const backgroundCount = 6;
    const randomizedImageIndex = computed(() => Math.floor(Math.random() * backgroundCount + 1));
    const image = `image-${randomizedImageIndex.value}`;

    const buzzwords = ['sales', 'growth', 'revenue', 'success', 'deals'];

    const currentBuzzwordIndex = ref(0);
    const currentBuzzword = computed(() => buzzwords[currentBuzzwordIndex.value]);
    const buzzwordContainer: Ref<HTMLDivElement> = ref(null);

    const switchWord = () => {
      currentBuzzwordIndex.value =
        currentBuzzwordIndex.value === buzzwords.length - 1 ? 0 : ++currentBuzzwordIndex.value;
    };

    onMounted(() => {
      buzzwordContainer.value.addEventListener('animationiteration', switchWord, false);
    });

    onBeforeUnmount(() => {
      buzzwordContainer.value.removeEventListener('animationiteration', switchWord, false);
    });

    return { image, buzzwordContainer, currentBuzzword };
  },
});
</script>

<style lang="scss" scoped>
.image-carosel {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;

  &.image-1 {
    background-image: url('../assets/1.webp');
  }

  &.image-2 {
    background-image: url('../assets/2.webp');
  }

  &.image-3 {
    background-image: url('../assets/3.webp');
    background-position: right;
  }

  &.image-4 {
    background-image: url('../assets/4.webp');
  }

  &.image-5 {
    background-image: url('../assets/5.webp');
  }

  &.image-6 {
    background-image: url('../assets/6.webp');
  }

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;
    position: relative;

    h2 {
      margin: 0;
      font-size: var(--h2-font-size);

      &.title {
        color: var(--gray-98);
      }
    }

    .word-slider {
      overflow: hidden;
      height: var(--h2-line-height);

      h2 {
        animation: slide-up 1.5s infinite;
        animation-timing-function: ease-in-out;
        animation-delay: 1s;
        position: relative;
        text-transform: capitalize;
      }
    }
  }
}

@keyframes slide-up {
  0% {
    top: 25px;
  }

  20% {
    top: 0;
  }

  80% {
    top: 0;
  }

  100% {
    top: -25px;
  }
}
</style>
