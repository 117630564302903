<template>
  <div class="password-validator-container">
    <div :class="['validation-step', validClass(hasMinCharacters)]">
      <FriedIcon :key="icon(hasMinCharacters)" :icon="icon(hasMinCharacters)" />
      <span>8 {{ $t('characters') }}</span>
    </div>
    <div :class="['validation-step', validClass(hasUpperCase)]">
      <FriedIcon :key="icon(hasUpperCase)" :icon="icon(hasUpperCase)" />
      <span>1 {{ $t('uppercase') }}</span>
    </div>
    <div :class="['validation-step', validClass(hasLowerCase)]">
      <FriedIcon :key="icon(hasLowerCase)" :icon="icon(hasLowerCase)" />
      <span>1 {{ $t('lowercase') }} </span>
    </div>
    <div :class="['validation-step', validClass(hasNumber)]">
      <FriedIcon :key="icon(hasNumber)" :icon="icon(hasNumber)" />
      <span>1 {{ $t('number') }} </span>
    </div>
  </div>
</template>

<script lang="ts">
import type { IconName } from '@getaccept/fried-tofu';
import { computed, defineComponent, toRefs, watch } from 'vue';

export default defineComponent({
  props: {
    input: {
      type: String,
      default: '',
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['is-valid-password'],
  setup(props, { emit }) {
    const { input, showErrors } = toRefs(props);

    watch(input, () => {
      emit('is-valid-password', isValidPassword);
    });

    const hasMinCharacters = computed((): boolean => {
      const minLengthRegex = /.{8,}/;
      return validateInput(input.value, minLengthRegex);
    });

    const hasUpperCase = computed((): boolean => {
      const uppercaseRegex = /[A-Z]/;
      return validateInput(input.value, uppercaseRegex);
    });

    const hasLowerCase = computed((): boolean => {
      const lowercaseRegex = /[a-z]/;
      return validateInput(input.value, lowercaseRegex);
    });

    const hasNumber = computed((): boolean => {
      const numberRegex = /\d/;
      return validateInput(input.value, numberRegex);
    });

    const isValidPassword = computed(
      (): boolean => hasMinCharacters.value && hasUpperCase && hasLowerCase.value && hasNumber.value
    );

    const validClass = (isValid: boolean): string => {
      if (isValid) {
        return 'valid';
      }
      if (!showErrors.value) {
        return '';
      }

      return 'invalid';
    };

    const icon = (isValid: boolean): IconName => {
      if (!showErrors.value) {
        return 'check-circle';
      }
      return isValid ? 'check-circle' : 'close-circle';
    };

    const validateInput = (input: string, regex: RegExp) => regex.test(input);

    return {
      validClass,
      icon,
      hasNumber,
      hasLowerCase,
      hasUpperCase,
      hasMinCharacters,
    };
  },
});
</script>

<style lang="scss" scoped>
.password-validator-container {
  padding-left: var(--spacing-100);
  display: grid;
  grid-template-columns: repeat(2, min-content);
  column-gap: var(--spacing-100);
  row-gap: var(--spacing-50);

  .validation-step {
    color: var(--gray-39);
    display: flex;
    align-items: center;
    width: min-content;
    font-family: var(--default-font-family);
    font-weight: var(--label-small-font-weight);
    font-size: var(--label-small-font-size);
    line-height: var(--label-small-line-height);
    margin-block-start: 0;
    margin-block-end: 0;
    transition: color 0.2s;

    span {
      white-space: nowrap;
    }

    svg {
      transition: color 0.2s;
      width: 14px;
      height: 14px;
      margin-right: var(--spacing-50);
    }

    &.valid {
      color: var(--success-dark);

      svg {
        color: var(--success-dark);
      }
    }

    &.invalid {
      color: var(--error-dark);

      svg {
        color: var(--error-dark);
      }
    }
  }
}
</style>
