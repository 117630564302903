import type { LocalStorageKey } from '@getaccept/lib-shared/src/enums/local-storage';

export class LocalStorageHelper {
  static reset(key: string) {
    localStorage.removeItem(key);
  }
  static setLocalStorage(key: LocalStorageKey, value: string): void {
    localStorage.setItem(key, value);
  }
  static getLocalStorage(key: LocalStorageKey): string {
    return localStorage.getItem(key);
  }
}
