import { t } from './translation';

export class PasswordValidation {
  static validateNewPassword(newPassword: string, isValid: boolean): string {
    if (!newPassword) {
      return t('password-is-required');
    }
    if (newPassword.length < 8) {
      return t('your-password-must-be-at-least-8-characters');
    }
    if (!isValid) {
      return t('your-password-isnt-strong-enough');
    }
    return '';
  }

  static validateNewPasswordRepeat(password: string, passwordRepeat: string): string {
    if (password !== passwordRepeat) {
      return t('passwords-do-not-match');
    }
    return '';
  }
}
