<template>
  <div class="paper-plane">
    <FriedIcon class="plane-icon" icon="send" />
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.plane-icon {
  width: 3.5rem;
}

.paper-plane {
  margin: 3rem auto;
  width: min-content;
}
</style>
