import type { RecaptchaAction } from '@getaccept/lib-shared/src/recaptcha/recaptcha-action';

export class RecaptchaService {
  static async getToken(action: RecaptchaAction): Promise<string> {
    const recaptcha_siteid = import.meta.env.VUE_APP_RECAPTCHA_SITEID;
    if (!recaptcha_siteid) {
      throw new Error('Recaptcha does not exist');
    }
    return await (window as any).grecaptcha?.execute(recaptcha_siteid, {
      action,
    });
  }
}
