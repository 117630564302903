import { createRouter, createWebHistory } from 'vue-router';
import ResetRequest from '../reset-request/components/ResetRequest.vue';
import ResetPassword from '../reset-password/components/ResetPassword.vue';
import SelectEntity from '../select-entity/components/SelectEntity.vue';
import PasswordExpired from '../password-expired/components/PasswordExpired.vue';
import Login from '../login/components/Login.vue';

const router = createRouter({
  history: createWebHistory(
    (window as any)._basePath ||
      import.meta.env.MODE === 'development' ||
      window.location.hostname === 'localhost'
      ? '/login/'
      : '/'
  ),
  routes: [
    {
      path: '/reset/',
      name: 'request',
      meta: {
        title: 'Reset password',
      },
      component: ResetRequest,
    },
    {
      path: '/reset/:userId/:passwordKey',
      name: 'reset-password',
      meta: {
        title: 'Reset password',
      },
      component: ResetPassword,
    },
    {
      path: '/password-expired',
      name: 'password-expired',
      meta: {
        title: 'Password expired',
      },
      component: PasswordExpired,
    },
    {
      path: '/select-entity',
      name: 'select-entity',
      meta: {
        title: 'Select entity',
      },
      component: SelectEntity,
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'login',
      meta: {
        title: 'Login',
      },
      component: Login,
    },
  ],
});

router.beforeEach((to, _from, next) => {
  if (to.meta.title) {
    document.title = `${to.meta.title} | GetAccept`;
    return next();
  }

  document.title = `GetAccept`;
  next();
});

export default router;
