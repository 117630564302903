export enum ErrorKey {
  Locked,
  Unknown,
  VerifyEmail,
  InvalidCredentials,
  AccountDeleted,
  WeakPassword,
  SamePassword,
  Throttled,
  InvalidKey,
  InvalidPassword,
  FetchIdFailed,
  UserNotConnected,
  LoadEntity,
  InvalidOTP,
  OTPResent,
  FetchSamlUrlFailed,
}
